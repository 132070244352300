.landingHeaderContainer {
  position: fixed;
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  height: 10vh;
  z-index: 100;
  margin-top: 2vh;
}

.leftHeader h1 a, .rightHeader h2 a {
  transition: color 0.5s ease, font-size 0.5s ease; /* Smooth transition for color and font-size */
}

.leftHeader h1 a:hover, .rightHeader h2 a:hover {
  color: #6184D8; /* New color on hover */
  font-size: larger; /* Increase the font size */
}

.leftHeader h1 a {
  text-decoration: none;
  color: black;
}

.rightHeader h2 a {
  text-decoration: none;
  color: black;
}


.landingHeader {
  display: flex;
  align-items: center; 
  width: 90%;
  height: 10vh;
  border-radius: 30px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.landingHeader.scrolled {
    background-color: white;
    border: 2px solid rgba(232, 231, 231, 1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.rightHeader {
  margin-left: auto;
  display: flex;
  align-items: center; 
  margin-right: 2vw;
}

.landingHeader h1 {
  margin-left: 2vw;
  font-family: YoungSerif;
  font-size: 3vw;
}

.landingHeader h2 {
  font-family: YoungSerif;
  padding: 1vw;
  font-size: 2.5vw;
}

.rightHeader .muibutton {
  text-transform: none; /* Add this line */
  font-family: YoungSerif;
  font-size: 1.5vmax; 
  border-radius: 20px;
  align-self: center;
}

.rightHeader .muibutton:hover {
    background-color: #5472ba; /* Replace <desired_hover_color> with your preferred color */
}

@media (max-width: 768px) {
  .landingHeader .rightHeader .aboutButton {
    margin-right: 2vw;
  }
  .landingHeader .leftHeader h1 {
    font-size: 6vw;
    margin-left: 5vw;
  }

  .landingHeader h2 {
    font-size: 6vw;
  }

  .headLine .muibutton {
    font-size: 5vw;
    border-radius: 20px;
  }

  .headLine {
    align-items: center;
    justify-content: center;
  }

  .headLine h1 {
    font-size: 8vw;
    width: 80vw;
  }

  .rightHeader {
    margin-right: 5vw;
  }

  .rightHeader .muibutton {
    font-size: 5vw;
  }

  .landingHeader {
    justify-content: space-between;
    // width: 100%;
    width: 95%;
  }

  .landingHeaderContainerMobile {
    display: flex;
    align-items: center; 
    justify-content: center;
    width: 100%;
    height: 10vh;
    z-index: 100;
    margin-top: 2vh;
    position: fixed;
  }

}