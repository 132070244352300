.aboutPageContainer {
    height: 100%;
    width: 100%;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding-top: 8%;
}

.GroupPhoto {
    height: 500px;
    width: auto;
    border-radius: 20px;
    display: flex;
    transition: transform 0.3s ease-in-out; /* Smooth transition for transform property */
}

.GroupPhoto:hover {
    transform: scale(1.05); /* Increase the size of the image by 10% */
}

.sectionContainer {
    display: flex;
    height: 100%;
    width: 100%;

    align-items: center;
    justify-content: center;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    margin: 0 2%;
    transition: transform 0.3s ease-in-out, border 0.3s ease-in-out; /* Transition for transform and border */
    border: 5px solid transparent; /* Default border, transparent to avoid layout shift */
    border-radius: 20px;
    margin-top: 1%;
}

.section:hover {
    transform: scale(1.05); /* Increase the size of the section by 5% */
    border-color: #5472ba; /* Change border color to purple on hover */
}

.sectionTitle {
    font-family: DMSerifDisplay;
    font-size: 2em;
    margin-left: 1%;
}

.sectionContent {
    font-family: YoungSerif;
    font-size: 1.2em;
    margin-left: 1%;
    color: grey;
}

@media (max-width: 768px) {
    .aboutPageContainerMobile {
        padding-top: 30%;
        height: 100%;
        width: 100%;
    
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    .GroupPhoto {
        height: 180px;
    }

    .sectionContainerMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0 2%;
    }

    .section {
        width: 80%;
    }

    .sectionTitle {
        font-family: DMSerifDisplay;
        font-size: 1.7em;
    }
    
    .sectionContent {
        font-family: YoungSerif;
        font-size: 1em;
    }
}