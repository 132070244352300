@import url('./style/LandingView.scss');
// @import url('./style/Redesign.scss');

@font-face {
  font-family: 'YoungSerif';
  src: local('YoungSerif'), url(../assets/fonts/YoungSerif-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans'), url(../assets/fonts/DMSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSerifDisplay';
  src: local('DMSerifDisplay'), url(../assets/fonts/DMSerifDisplay-Regular.ttf) format("truetype");
}

// overwrite the default browser styles
body {
  margin: 0;
  padding: 0;
}