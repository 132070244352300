.page2Container {
    // height: 100vh;
    width: 100%;
}

.videoPlayerOverlay {
    position: fixed; /* Instead of absolute */
    top: 0; /* Align to the top of the viewport */
    left: 0; /* Align to the left of the viewport */
    height: 100vh; /* 100% of the viewport height */
    width: 100vw; /* 100% of the viewport width */
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.marqueeThingy {
    // height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.marqueeContainer {
    // height: 34vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}
.marqueeIphone {
    width: 240px;
    height: 320px;

    border-radius: 10px;
    border: 2px solid black;

    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    margin-left: 5px;
    margin-top: 5px;

    font-size: 1.5vmin;
}

.playIcon {
    color: rgb(244, 141, 134);
    font-size: 2em;
}

.customDivider {
    border: none;         /* Removes default border */
    height: 1px;          /* Sets the thickness of the line */
    width: 80%;
    background-color: #000; /* Color of the line */
}

.marqueeIphone h1 {
    font-size: 30px;
    width: 80%;
    text-align: center;
}

.marqueeIphone h2 {
    color: grey;
    font-size: 20px;
    width: 80%;
    text-align: center;
}

.marqueeIphone:hover {
    cursor: pointer;
    filter: brightness(75%); /* Adjust the percentage to your preference */
}

.middleMarqueeText {
    // height: 20vh;
    width: 95vw;
    font-size: 1.5vmin;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    padding-bottom: 2vh;
}
.middleMarqueeText h1 {
    font-family: 'DMSerifDisplay', sans-serif;
    font-size: 3em;
    text-align: center;
    margin-top: 0px;
}
.middleMarqueeText h2 {
    font-family: 'DMSerifDisplay', sans-serif;
    font-size: 2em;
    text-align: center;
    color: gray;
    margin-top: 0vh;
}

.highlight {
    color: #6184D8;
}

.playerWrapper {
    height: 70vh;
    width: 70vw;
    min-height: 225px;
}

.closeButton {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    font-size: 2em;
    color: white;
}

@media (max-width: 768px) {
    .middleMarqueeText {
        text-align: center;
        font-family: DMSerifDisplay;
        font-size: 40px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }

    .marqueeIphone {
        width: 40vw;
        height: 33vh;
    
        border-radius: 10px;
        border: 2px solid black;
    
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    
        margin-left: 5px;
        margin-top: 5px;
    
        font-size: 3vh;
    }

    .marqueeIphone h1 {
        font-size: 1em;
    }
    
    .marqueeIphone h2 {
        font-size: .8em;
    }

    .middleMarqueeText h1 {
        font-family: 'DMSerifDisplay', sans-serif;
        font-size: .7em;
        text-align: center;
        margin-top: 0px;
    }
    .middleMarqueeText h2 {
        font-family: 'DMSerifDisplay', sans-serif;
        font-size: .5em;
        text-align: center;
        color: gray;
        margin-top: 0vh;
    }
}