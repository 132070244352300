.closingSection {
    background-color: #6184D8;
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closingText {
    font-family: 'DMSerifDisplay', sans-serif;
    font-size: 2vmin;
}
.closingText h1 {
    font-size: 4em;
    margin-top: 0px;
    color: white;
    margin-left: 2vw;
}

.closingText h2 {
    font-size: 2.5em;
    margin-top: 0vh;
    color: black;
    margin-left: 2vw;
}

.downloadLink {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    background-color: black;
    height: 100px;
    border-radius: 20px;
    border: 2px solid grey;
    width: 240px;
    text-decoration: none;
    font-size: 1.5vmin;
}

.downloadText {
    font-size: 25px;
}

.downloadLink .appleLogo {
    font-size: 4em;
    color: white
}

.downloadLink .downloadText {
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

.downloadLink .downloadText h1{
    color: white;
    margin-bottom: 30px;
    height: 20px;
    margin-top: 5px;
    font-size: 1em;
}

.downloadLink .downloadText h2{
    color: white;
    font-size: 20px;
    height: 20px;
    font-size: .7em;
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .closingSection {
        flex-direction: column;
        justify-content: center;
    }
}