/*  Landing Page Stuff */
.landingPage {
    height: 100vh;
    width: 95vw;
    display: flex; 
    flex-direction: column;
    justify-content: center; 
  }

.landingBody {
  height: 90vh;
  width: 95vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cutout {
  transform: rotate(340deg);
  right: 20vw;
  z-index: 1;
}
.cutout2 {
  transform: rotate(15deg);
  right: 10vw;
}
.cutout, .cutout2 {
  top: 15vh;
  position: absolute;
  height: 60vh;
  // width: 30vmin;
}

.headLine {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 10vw;
  font-size: 5vmin;
}

.headLine .muibutton {
  text-transform: none; /* Add this line */
  font-family: YoungSerif;
  font-size: 1.5vmax; 
  border-radius: 20px;
  align-self: center;
  margin-top: 2vh;
}

.headLine .muibutton:hover {
    background-color: #5472ba; /* Replace <desired_hover_color> with your preferred color */
}

.headLine h1 {
  margin: 1vh; /* Removes default margin */
  line-height: 1.2; /* Adjusts the line height to reduce space between lines */
  font-family: YoungSerif;
  font-size: 1.3em;
}

.headLine h2 {
  margin: 1vh; /* Removes default margin */
  line-height: 1.2; /* Adjusts the line height to reduce space between lines */
  font-family: YoungSerif;
  font-size: .6em;
  color: grey;
  margin-top: 1vh;
}


.headLine h1:nth-child(2) {
  padding-bottom: 3vh; /* Adjust the value as needed */
}

.youText {
  color: #6184D8
}

@media (max-width: 768px) {
  .headLine .muibutton {
    font-size: 5vw;
    border-radius: 20px;
  }

  .headLine {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    font-size: 1vw;
  }

  .headLine h1 {
    width: 80%;
    text-align: center;
    font-size: 2em;
  }

  .headLine h2 {
    font-size: 4em;
    text-align: center;
    width: 80%;
  }

  .landingPage {
    height: 100vh;
    width: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
  }

  .landingBody {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

}