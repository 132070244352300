.page4Container {
    width: 100%;
    // height: 80vh;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.page4Title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page4Title h1 {
    font-size: 40px;
    font-family: YoungSerif;
    text-align: center;
}

.page4IconAndText {
    flex-direction: column;
    width: 25vw;
    align-items: center;
    display: flex;
}

.page4IconAndTextMid {
    flex-direction: column;
    width: 25vw;
    margin-left: 100px;
    margin-right: 100px;
    align-items: center;
    display: flex;
}

.page4IconAndTextMid img {
    width: 70%;
}

.page4IconAndText img {
    width: 70%;
}

.textContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textContainer h1 {
    margin-top: 4vh;
    font-size: 25px;
    font-family: YoungSerif;
    text-align: center;
}
.textContainer h2 {
    font-size: 15px;
    color: grey;
    font-family: YoungSerif;
}

.textContainer .containerIcon {
    color: #6184D8;
    padding: 10px;
    height: 3vh;
    width: 1.5vw;
    border-radius: 10px;
    background-color: #b5c9f8;
}


@media (max-width: 768px) {
    .page4Container {
        width: 100%;
        // height: 150vh;
    }

    .page4Container .mobileContainer {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
    }

    .mobileContainer h1 {
        font-size: 25px;
        font-family: YoungSerif;
    }
    .mobileContainer h2 {
        font-size: 15px;
        color: grey;
        font-family: YoungSerif;
    }
    
    .mobileContainer .containerIcon {
        margin-top: 3vh;
        color: #6184D8;
        padding: 10px;
        height: 3vh;
        border-radius: 10px;
        background-color: #b5c9f8;
    }
    
    .textContainer .bot {
        margin-bottom: 5vh;
    }
}